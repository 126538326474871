<template>
  <v-col cols="12">
    <v-row>
      <v-col cols="12">
        <data-table
          :headers="headers"
          :items="items"
          :options.sync="options"
          :total="items.length"
        >
          <template v-slot:[`item.start_at`]="{ item }">
            {{ parseDate(item.start_at) }}
          </template>
          <template v-slot:[`item.end_at`]="{ item }">
            {{ parseDate(item.end_at) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <report-job-module-actions
              :value="item.id"
              :item="item"
              @cancel="cancelItem"
              @download="downloadItem"
            />
          </template>
          <template v-slot:[`item.company_id`]="{ item }">
            <company-preview-by-id :company-id="item.company_id" />
          </template>
        </data-table>
      </v-col>
    </v-row>
  </v-col>
</template>

<script lang="ts">
import { Component, Mixins, Vue } from "vue-property-decorator";
import type { DataOptions, DataTableHeader } from "vuetify";
import { ReportJob, type ReportJobData } from "@planetadeleste/vue-mc-gw";
import { get, set } from "lodash";
import dayjs from "dayjs";
import ReportJobModuleActions from "../components/ReportJobModuleActions.vue";
import Utils from "@/services/Utils";
import ReportJobMixin from "../mixins/ReportJobMixin";
import { AuthModule } from "@/store/auth";
import CompanyPreviewById from "../components/CompanyPreviewById.vue";

@Component({
  components: {
    ReportJobModuleActions,
    CompanyPreviewById,
  },
})
export default class ReportJobList extends Mixins(ReportJobMixin) {
  isList = true;
  states = ["waiting", "working", "completed", "failed", "canceled"];

  options: DataOptions = {
    page: 1,
    itemsPerPage: 20,
    sortBy: [],
    sortDesc: [false],
    groupBy: [],
    groupDesc: [false],
    multiSort: false,
    mustSort: false,
  };

  get userIsAdmin() {
    return AuthModule.isAdmin;
  }

  onMounted() {
    const arHeaders: DataTableHeader[] = [
      { text: "Job ID", value: "job_id" },
      { text: "Status", value: "status" },
      { text: "Queue", value: "queue" },
      { text: "Start At", value: "start_at" },
      { text: "End At", value: "end_at" },
      { text: "Acciones", value: "actions" },
    ];

    if (this.userIsAdmin) {
      arHeaders.splice(1, 0, { text: "Company ID", value: "company_id" });
    }
    this.delDTHeader(["name", "active"]);
    this.addDTHeaders(arHeaders);
    // this.index();
  }

  parseDate(date: string) {
    return dayjs(date).format("L");
  }

  onBeforeIndex(obFilters: Record<string, any>) {
    set(obFilters, "canceled", 0);
    set(obFilters, "completed", 0);
    set(obFilters, "failed", 0);
    set(obFilters, "waiting", 1);
  }

  async cancelItem(item: ReportJobData) {
    const obModel = new ReportJob(item);
    await obModel.cancel();
  }

  get items() {
    return this.obCollection.getModelList();
  }

  async downloadItem(item: any) {
    const obModel = new ReportJob(item);
    const obResponse = await obModel.download();
    const blob = obResponse.response
      ? get(obResponse, "response.data.data")
      : null;
    Utils.downloadBlob(blob, `${"test"}.pdf`);
  }

  async onLoadIndex(sValue: string) {
    if (sValue !== this.sModelName) {
      return;
    }
    this.index();
  }

  onRegisterEvents() {
    this.addEvent("filters.change", this.index);
    this.addEvent("model.mounted", this.onLoadIndex);
  }
}
</script>
