<template>
  <v-menu v-model="menu" bottom left offset-x>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :disabled="!(hasPath || canCancel)"
        color="primary"
        icon
        v-bind="attrs"
        v-on="on"
      >
        <icon-ellipsis-vertical-sharp />
      </v-btn>
    </template>

    <v-list>
      <slot name="append-items" :close="closeMenu" />

      <v-list-item v-if="hasPath" @click="onDownload">
        <v-list-item-icon>
          <icon-download />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="$t('download')" />
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="canCancel" @click="onCancel">
        <v-list-item-icon>
          <icon-trash />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="$t('cancel')" />
        </v-list-item-content>
      </v-list-item>

      <slot name="prepend-items" :disabled="disabled" :close="closeMenu" />
    </v-list>
  </v-menu>
</template>

<script lang="ts">
import { type ReportJobData } from "@planetadeleste/vue-mc-gw";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class ReportJobModuleActions extends Vue {
  @Prop([String, Number]) readonly value!: any;
  @Prop(Boolean) readonly disabled!: boolean;
  @Prop(String) readonly baseRouteName!: string;
  @Prop({ type: String, default: "update" }) readonly updateRoute!: string;
  @Prop({ type: String, default: "id" }) readonly updateKey!: string;
  @Prop() readonly item!: ReportJobData;

  menu = false;

  mounted() {
    //TODO el back no trae hasPath
    //console.log('eze2', this.hasPath, this.canCancel);
  }

  closeMenu() {
    this.menu = false;
  }

  onCancel() {
    this.$emit("cancel", this.item);
    this.closeMenu();
  }

  onDownload() {
    this.$emit("download", this.item);
    this.closeMenu();
  }

  get hasPath() {
    return this.item.path;
  }

  get canCancel() {
    const enableStatus = [
      "waiting",
      "working",
      //'completed',
      //'failed',
      //'canceled',
    ];
    return enableStatus.includes(this.item.status);
  }

  get sBaseRoute() {
    if (this.baseRouteName) {
      return this.baseRouteName;
    }

    return this.$route.name && this.$route.name.includes(".")
      ? this.$route.name.split(".").shift()
      : this.$route.name;
  }

  get obEditRoute() {
    if (!this.value || !this.sBaseRoute) {
      return null;
    }

    return {
      name: `${this.sBaseRoute}.${this.updateRoute}`,
      params: { [this.updateKey]: this.value },
    };
  }
}
</script>
