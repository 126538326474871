<template>
  <div v-frag>
    <div class="text-body-2" />
    <name-with-avatar :items="items">
      <template #subtitle>
        <slot />
      </template>
    </name-with-avatar>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import NameWithAvatar from "@/components/common/NameWithAvatar.vue";
import { AppModule } from "@/store/app";
import type { CompanyData } from "@planetadeleste/vue-mc-gw";
import { Company } from "@planetadeleste/vue-mc-gw";
import useCompanyHelper from "@/composables/company";

@Component({
  components: {
    NameWithAvatar,
  },
})
export default class CompanyPreviewById extends Vue {
  @Prop({ type: Number }) readonly companyId!: number;
  company: Company | undefined;
  items: any = [];
  mapData(obItem: CompanyData) {
    return this.$_.assign(obItem, {
      path: obItem.preview_image,
      name: obItem.firm.name,
    });
  }

  async mounted() {
    const obCompanyClass = useCompanyHelper(
      new Company({ id: this.companyId })
    );
    this.company = await obCompanyClass.showBasic();
    this.items.push(this.company);
    this.$_.map(this.items, this.mapData);
  }

  get obCompany() {
    return this.company
      ? this.company instanceof Company
        ? this.company.attributes
        : this.company
      : AppModule.company.attributes;
  }
}
</script>
